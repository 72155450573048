.App {
    text-align: center;
  }
  
  .background-image {
    height: 115vh;  /* Full height of the viewport */
    width: 100vw;   /* Full width of the viewport */
    background-size: cover;
    background-position: center;
    scroll-snap-align: start;
  }
  
  .background-image:nth-child(2), .background-image:nth-child(4) { /* Targeting both separators */
    margin-top: 0px; /* Adjust if you need any top margin */
    height: 10vh; /* Adjust this value to shrink the image as desired */
    filter: blur(8px); /* Adding blur effect */
  }
  
  body, html {
    margin: 0;
    padding: 0;
    scroll-snap-type: y mandatory; /* Ensures that the scrolling snaps to each full-page image */
    overflow-x: hidden; /* Prevents horizontal scrolling */
  }
  