.game-footer {
    background-color: #210a20; /* Dark background */
    color: white;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-icons {
    margin-bottom: 20px;
  }
  
  .footer-icons a {
    margin: 0 10px;
  }
  
  .footer-icons img {
    height: 30px; /* Adjust based on your preference */
  }
  
  .footer-info p {
    margin: 5px 0;
    font-size: 0.8rem;
  }
  